// ** Redux Imports
import API from "@src/service";
import END_POINTS from "@configs/apiEndPointsConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateSplitPercentage } from "@views/private/users/store";

export const getListData = createAsyncThunk(
  "agencyYumyhub/getData",
  async (params) => {
    const response = await API.get(
      END_POINTS.PRIVATE.AGENCY_YUMYHUB.LIST,
      params
    );
    return {
      status: response.status,
      message: response.message,
      totalItems: response?.data?.totalItems || 0,
      totalPages: response?.data?.totalPages || 1,
      currentPage: response?.data?.currentPage || 1,
      data: response?.data.records || [],
      params,
    };
  }
);

const initialState = {
  list: {
    isLoading: false,
    status: null,
    totalItems: 0,
    totalPages: 1,
    currentPage: 1,
    data: [],
    params: {},
  },
};
export const agencyMembersSlice = createSlice({
  name: "agencyYumyhub",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListData.pending, (state) => {
        state.list = {
          ...state.list,
          isLoading: true,
        };
      })
      .addCase(getListData.fulfilled, (state, action) => {
        state.list = {
          ...state.list,
          isLoading: false,
          status: action.payload.status,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          params: action.payload.params,
          data: action.payload.data,
        };
      })
      .addCase(updateSplitPercentage.fulfilled, (state, action) => {
        const {
          payload: {
            status,
            requestData: { userId, accessMode, payload },
          },
        } = action;
        const userList = state.list.data;

        if (state.list.params?.list_type === "active") {
          if (accessMode === "agency_members") {
            const newList = userList.map((item) =>
              item.id === userId
                ? {
                    ...item,
                    payment_split_percentage: `${Number(
                      payload.paymentSplitPercentage
                    ).toFixed(2)}`,
                  }
                : item
            );
            state.list.data = newList;
          }
          if (accessMode === "remove_agency_members") {
            const newList = userList.filter((item) => item.id !== userId);
            state.list.data = newList;
          }
        } else if (state.list.params?.list_type === "past") {
          if (accessMode === "agency_members") {
            const newList = userList.filter((item) => item.id !== userId);
            state.list.data = newList;
          }
        }

        state.updateSplitPercentage = {
          success: status,
          isLoading: false,
        };
      });
  },
});
export default agencyMembersSlice.reducer;
