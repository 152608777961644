// ** Redux Imports
import API from '@src/service'
import END_POINTS from '@configs/apiEndPointsConfig'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getData = createAsyncThunk('releaseForms/getData', async params => {
    const response = await API.get(END_POINTS.PRIVATE.RELEASE_FORM.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})
export const getReleaseFormDetail = createAsyncThunk('releaseForms/getReleaseFormDetail', async requestFormId => {
    const response = await API.get((END_POINTS.PRIVATE.RELEASE_FORM.GET).replace(':id', requestFormId))
    return {
        ...response,
        status: response.status,
        message: response.message,
        data: response?.data || {}
    }
})

export const actionOnReleaseFormRequest = createAsyncThunk('releaseForms/actionOnReleaseFormRequest', async params => {
    const response = await API.patch(END_POINTS.PRIVATE.RELEASE_FORM.ACTION_ON_REQUEST, params)
    return {
        status: response.status,
        message: response.message,
        requestData: {
            ...params
        }
    }
})

const initialState = {
    list: {
        isLoading: false,
        status: null,
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        data: [],
        params: {}
    },
    singleFormDetail: {
        message: '',
        isRecordFound: true,
        isLoading: false,
        data: {}
    },
    actionOnReleaseFormRequest: {
        success: null,
        isLoading: false,
        message: ''
    }
}
export const releaseFormsSlice = createSlice({
    name: 'releaseForms',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.pending, (state) => {
                state.list = {
                    ...state.list,
                    isLoading: true
                }
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.list = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })
            .addCase(getReleaseFormDetail.pending, (state) => {
                state.singleFormDetail = {
                    ...initialState.singleFormDetail,
                    isRecordFound: true,
                    isLoading: true
                }
            })
            .addCase(getReleaseFormDetail.fulfilled, (state, action) => {
                const {
                    isRecordFound = true,
                    data,
                    message
                } = action.payload
                state.singleFormDetail = {
                    ...state.singleFormDetail,
                    data,
                    message,
                    isRecordFound,
                    isLoading: false
                }
            })
            .addCase(actionOnReleaseFormRequest.pending, (state) => {
                state.actionOnReleaseFormRequest = {
                    ...initialState.actionOnReleaseFormRequest,
                    isLoading: true
                }
            })
            .addCase(actionOnReleaseFormRequest.fulfilled, (state, action) => {
                let listData = state.list.data
                if (action.payload.status) {
                    //Removed action report record
                    listData = listData.filter(item => {
                        return item.id !== action.payload.requestData?.release_form_id
                    })
                }
                state = {
                    ...initialState,
                    ...state,
                    list: {
                        ...state.list,
                        data: listData
                    },
                    actionOnReleaseFormRequest: {
                        ...state.actionOnReleaseFormRequest,
                        isLoading: false,
                        message: action.payload.message,
                        data: action.payload.data,
                        success: action.payload.status || false
                    }
                }
                return state
            })
            .addCase(actionOnReleaseFormRequest.rejected, (state, action) => {
                state.actionOnReleaseFormRequest = {
                    ...state.actionOnReleaseFormRequest,
                    isLoading: false,
                    success: false,
                    message: action?.payload?.message
                }
            })
    }
})

export default releaseFormsSlice.reducer
