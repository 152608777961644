// ** Redux Imports
import API from '@src/service'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import END_POINTS from '@configs/apiEndPointsConfig'

export const getPreviousList = createAsyncThunk('seriesTaxForms/getW8FormList', async params => {
    const response = await API.get(END_POINTS.PRIVATE.PAYOUT.PREVIOUS.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})
export const getUpcomingList = createAsyncThunk('seriesTaxForms/getW9FormList', async params => {
    const response = await API.get(END_POINTS.PRIVATE.PAYOUT.UPCOMING.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        grandTotal: response?.data?.grandTotal || 0,
        data: response?.data.records || [],
        params
    }
})

const initialState = {
    upcoming: {
        list: {
            isLoading: false,
            status: null,
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            grandTotal: 0,
            data: [],
            params: {}
        }
    },
    previous: {
        list: {
            isLoading: false,
            status: null,
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            data: [],
            params: {}
        }
    }
}

export const payoutsSlice = createSlice({
    name: 'payouts',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPreviousList.pending, (state) => {
                state.previous.list = {
                    ...state.previous.list,
                    isLoading: true
                }
            })
            .addCase(getPreviousList.fulfilled, (state, action) => {
                state.previous.list = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })

            .addCase(getUpcomingList.pending, (state) => {
                state.upcoming.list = {
                    ...state.upcoming.list,
                    isLoading: true
                }
            })
            .addCase(getUpcomingList.fulfilled, (state, action) => {
                state.upcoming.list = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    grandTotal: action.payload.grandTotal,
                    data: action.payload.data
                }
            })
    }
})

export default payoutsSlice.reducer
