// **Redux Imports
import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit"
import API from '@src/service'

const initialState = {
    loginDetail: {
        success: null,
        isLoading: false,
        message: '',
        errorBag: []
    }
}

export const loginDetail = createAsyncThunk('admin/loginDetail', async params => {
    const response = await API.get('detail', params)
    return {
        status: response.status,
        data : response.data
    }
})

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loginDetail.pending, (state, action) => {
                state.loginDetail = {
                    ...initialState.loginDetail,
                    isLoading: true
                }
            })
            .addCase(loginDetail.fulfilled, (state, action) => {
                state.loginDetail = {
                    ...state.loginDetail,
                    isLoading: false,
                    success: action.payload.status,
                    info : action.payload.data
                }
            })
            .addCase(loginDetail.rejected, (state, action) => {
                state.loginDetail = {
                    ...state.loginDetail,
                    isLoading: false,
                    success: false,
                    message: action.payload.message
                }
            })
    }
})

export default adminSlice.reducer