import React from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from 'reactstrap'

const ModalConfirmation = (props) => {
    const {
        _logout,
        isLoading,
        openModal,
        setOpenModal
    } = props

    return (
        <div className={'theme-{item.modalColor}'}>
            <Modal
                isOpen={openModal}
                className='modal-dialog-centered'
                modalClassName='modal-warning'
            >
                <ModalHeader toggle={() => setOpenModal(false)}>{'Warning'}</ModalHeader>
                <ModalBody>
                    Are you sure you want to log out? Confirm and log out
                </ModalBody>
                <ModalFooter>
                    <Button color={'warning'} onClick={() => _logout()} disabled={isLoading}>
                        {!isLoading &&
                            'Confirm'
                        }
                        {isLoading &&
                            <>
                                <Spinner color='white' size='sm'/>
                                <span className='ms-50'>Please wait...</span>
                            </>
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default ModalConfirmation