/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
// ** Redux Imports
import API from '@src/service';
import END_POINTS from '@configs/apiEndPointsConfig';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const getListData = createAsyncThunk('earningSpending/getData', async (params) => {
  const response = await API.get(END_POINTS.PRIVATE.EARNING_SPENDING.LIST, params);
  return {
    status: response.status,
    message: response.message,
    totalItems: response?.data?.totalItems || 0,
    totalPages: response?.data?.totalPages || 1,
    currentPage: response?.data?.currentPage || 1,
    data: response?.data.records || [],
    params,
  };
});

export const getAgenciesList = createAsyncThunk('earningSpending/getAgencies', async (params) => {
  const response = await API.get(END_POINTS.PRIVATE.EARNING_SPENDING.AGENCIES, params);
  return {
    status: response.status,
    message: response.message,
    data: response?.data || [],
    params,
  };
});

const initialState = {
  list: {
    isLoading: false,
    status: null,
    totalItems: 0,
    totalPages: 1,
    currentPage: 1,
    data: [],
    params: {},
  },
  agenciesList: {
    isLoading: false,
    status: null,
    data: [],
    params: {},
  }
};
export const earningSpendingSlice = createSlice({
  name: 'earningSpending',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListData.pending, (state) => {
        state.list = {
          ...state.list,
          isLoading: true,
        };
      })
      .addCase(getListData.fulfilled, (state, action) => {
        state.list = {
          ...state.list,
          isLoading: false,
          status: action.payload.status,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          params: action.payload.params,
          data: action.payload.data,
        };
      })
      .addCase(getAgenciesList.pending, (state) => {
        state.agenciesList = {
          ...state.agenciesList,
          isLoading: true,
        };
      })
      .addCase(getAgenciesList.fulfilled, (state, action) => {
        state.agenciesList = {
          ...state.agenciesList,
          isLoading: false,
          status: action.payload.status,
          params: action.payload.params,
          data: action.payload.data,
        };
      })
  },
});
export default earningSpendingSlice.reducer;
